//(function () {
    //const video = document.querySelectorAll("[data-video-id]");

    //video.forEach(vid => {
        //const link = vid.dataset.videoId,
            //linkSplit = link.split("/"),
            //id = linkSplit[linkSplit.length - 1];

        //fetch(`https://vimeo.com/api/v2/video/${id}.json`)
            //.then((res) => res.json())
            //.then(data => {
                //console.log(data);
            //});
    //});
//})();
