/**
 * 1. vars 
 *
 * 2. map instance
 *
 * 3. map listings (optional component)
 *
 * 4. filters
 *     a. open / close filter panel
 *     b. set filters
 *     c. submit filters
 *     d. clear filters
 *     e. make filter panel keyboard accessible
 *
 * 5. callbacks
*/

import Map from "./Map.js";
import Filters from "./Filters.js";

(function() {
    document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
            //setTimeout(() => {
                //////////////////////////////////////////////////
                // 1. vars

                const mapWrapper = document.querySelector(`[data-map-listings]`), 
                    mapNode = document.querySelector(`[data-component="map"]`),
                    filterCategory = document.querySelector(`[data-filter="rating"]`),
                    filterRegion = document.querySelector(`[data-filter="region"]`),
                    openFilters = document.querySelector(`[data-open="filter-panel"]`),
                    closeFilters = document.querySelector(`[data-close="filter-panel"]`),
                    clearFilters = document.querySelector(`[data-filter="clear"]`),
                    keyboardToggles = ["Spacebar", " ", "Enter"];

                if (!mapWrapper) return;

                // end vars
                //////////////////////////////////////////////////


                //////////////////////////////////////////////////
                // 1. map instance
                
                const map = new Map({
                    map : mapNode,
                    api : "https://search.ehealth.nsw.gov.au/s/search.html?collection=heti-rural-generalist-map&query=%21padre&form=json",
                    //api : "externals/filters.json",
                    settings : {
                        mapTypeControl : false,
                        streetViewControl : false,
                        fullscreenControl : false,
                        zoomControl : true,
                        zoomControlOptions : {
                            rotateControl : true
                        }
                    }
                });

                map.bubble(mapBubble);

                //function initMap() {}
                //window.initMap = initMap;

                // end map instance
                //////////////////////////////////////////////////

                //////////////////////////////////////////////////
                // 3. map listings (optional component)
                 
                if (mapWrapper.dataset.mapListings === "true") {
                    const listingWrapper = document.createElement("div");

                    listingWrapper.setAttribute("class", "map-listings");
                    listingWrapper.setAttribute("data-map-listings-wrapper", "");

                    mapWrapper.append(listingWrapper);

                    map.getList(mapList);
                }

                // end map listings
                //////////////////////////////////////////////////

                //////////////////////////////////////////////////
                // 4. filters

                const filters = new Filters({
                    filterPanel : "[data-filter-panel]",
                    filterSubmit : document.querySelector(`[data-filter="submit"]`),
                    filterPanelOpenState : "[data-filters-open]",
                    closeFilterPanel : `[data-close="filter-panel"]`,
                    openButton : `[data-open="filter-panel"]`
                });

                // ======================================
                // a. open / close filter panel
                
                openFilters.addEventListener("click", () => {
                    filters.filterPanelState(true);
                });

                closeFilters.addEventListener("click", () => {
                    filters.filterPanelState(false);

                    openFilters.focus();
                });

                // end open / close filter panel
                // ======================================

                // ======================================
                // b. set filters
                
                // ratings
                filters.set("ratings", filterCategory, 3, [
                    { name : "MMM 7", value : "7", category : "rating" },
                    { name : "MMM 6", value : "6", category : "rating" },
                    { name : "MMM 5", value : "5", category : "rating" },
                    { name : "MMM 4", value : "4", category : "rating" },
                    { name : "MMM 3", value : "3", category : "rating" },
                    { name : "MMM 2", value : "2", category : "rating" },
                    { name : "MMM 1", value : "1", category : "rating" },
                ]);

                // regions
                filters.checkall(filterRegion, "Select All", "region");

                filters.set("regions", filterRegion, 3, [
                    { name : "Mid North Coast NSW LHD", value : "Mid north coast NSW LHD", category : "region"},
                    { name : "Northern NSW LHD", value : "Northern NSW LHD", category : "region" },
                    { name : "Western NSW LHD", value : "Western NSW LHD", category : "region" },
                    { name : "Murrumbidgee LHD", value : "Murrumbidgee LHD", category : "region" },
                    { name : "Hunter New England LHD", value : "Hunter New England LHD", category : "region" },
                    { name : "Illawarra Shoalhaven LHD", value : "Illawarra Shoalhaven LHD", category : "region" },
                    { name : "Southern NSW LHD", value : "Southern NSW LHD", category : "region" },
                    { name : "Far West NSW LHD", value : "Far West NSW LHD", category : "region" }
                ]);

                const filterBy = ["rating", "region"],
                    lhds = [
                        { name : "Mid North Coast NSW LHD", lat : -30.658731967918573, lng : 152.97150850758837 },
                        { name : "Northern NSW LHD", lat : -28.80895061327356, lng : 153.2544933898301 },
                        { name : "Western NSW LHD", lat : -30.770801227188528, lng : 148.27902034793047 },
                        { name : "Murrumbidgee LHD", lat : -34.918174130621004, lng : 145.80680037519545 },
                        { name : "Hunter New England LHD", lat : -30.538692871157238, lng : 151.65485265372712 },
                        { name : "Illawarra Shoalhaven LHD", lat : -34.8713979799532, lng : 150.56885500339484 },
                        { name : "Southern NSW LHD", lat : -35.6528178000715, lng : 149.32266393164826 },
                        { name : "Far West NSW LHD", lat : -29.843788197356666, lng : 141.897328857028 }
                    ];
                // end set filters
                // ======================================

                // ======================================
                // c. submit filters

                filters.submit((filter) => {
                    const listings = document.querySelectorAll("[data-map-listing]");

                    if (filter.region && filter.rating || filter.region && !filter.rating) {
                        map.moveMapToLocation(filter.region, lhds);
                    } 
                    else if (!filter.region && filter.rating) {
                        map.centerMap();
                    }

                    filters.filterPanelState(false);

                    listings.forEach(listing => {
                        const matchedFilters = [];

                        filterBy.forEach(cat => {
                            if (filter[cat]) {
                                if (filter[cat].indexOf(listing.dataset[cat]) >= 0) {
                                    matchedFilters.push(true);
                                } else {
                                    matchedFilters.push(false);
                                }
                            }
                        });

                        if (matchedFilters.indexOf(false) !== -1) {
                            listing.dataset.listingActive = false;
                        } else {
                            listing.dataset.listingActive = true;
                        }
                    });

                    map.refreshMarkers(filter, filterBy);
                    map.bubble(mapBubble, filter, filterBy);
                });

                // end submit filters
                // ======================================

                // ======================================
                // d. clear filters

                clearFilters.addEventListener("click", () => {
                    const listings = document.querySelectorAll("[data-map-listing]");

                    filters.filterPanelState(false);

                    listings.forEach(listing => {
                        listing.dataset.listingActive = true;
                    });

                    filters.clear();

                    map.refreshMarkers(filters, filterBy);
                    map.bubble(mapBubble, filters, filterBy);
                    map.centerMap();
                });

                // end clear filters
                // ======================================

                // ======================================
                // e. make filter panel keyboard accessible

                const filterOptions = document.querySelectorAll("[data-filter-option]");

                filterOptions.forEach(option => {
                    option.addEventListener("keyup", function (e) {
                        const { key } = e;

                        if (keyboardToggles.indexOf(key) !== -1) {
                            this.click();

                            e.preventDefault();
                            e.stopPropagation();
                            e.returnValue = false;
                            return false;
                        }
                    });
                });

                // end make filter panel keyboard accessible
                // ======================================

                // end filters
                //////////////////////////////////////////////////

                //////////////////////////////////////////////////
                // 6. callbacks
                
                // the map bubble callback
                function mapBubble (data) {
                    let { title, titleLink, img, rating, work, services, training, town, townLink } = data;

                    if (services) {
                        const tmp = document.createElement("div");

                        tmp.innerHTML = services;

                        const anchorTags = tmp.querySelectorAll("a");

                        anchorTags.forEach(anchor => {
                            anchor.setAttribute("target", "_blank");
                        });

                        services = tmp.innerHTML;
                    }

                    return `
                        <div class="map-bubble">

                            ${ img ? 
                                    `
                                        <header class="map-bubble__header">
                                            <img src="${ img }" alt="">
                                        </header>
                                    `
                                    :
                                    ""
                            }
                            
                            <main class="map-bubble__wrapper">
                                <summary class="map-bubble__body">
                                    <p class="map-bubble__title">
                                        <a class="map-bubble__title-link btn-text-blue ${ !img ? "map-bubble__title-link--no-header" : "" }" href="${ titleLink }" target="_blank">
                                            ${ title }

                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <rect y="0.496826" width="14" height="14" rx="7" fill="#3070B3"/>
                                                <path d="M6 4.49683L9 7.49683" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6 10.4968L9 7.49683" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a>
                                    </p>

                                    <p class="map-bubble__m-rating">MMM ${ rating }</p>

                                    ${ 
                                        training ? 
                                            `
                                                
												${ 
													!training.match(/<p><\/p>/) ? 
														`<p class="map-bubble__opportunities">AST Training Opportunities</p>` 
														: 
														"" 
												}

                                                ${ training }
                                            ` 
                                            : 
                                            "" 
                                    }

                                    ${ 
                                        work ? 
                                            `
				
												${ 
													!work.match(/<p><\/p>/) ? 
														`<p class="map-bubble__opportunities">AST Work Opportunities</p>` 
													: 
														"" 
												}

                                                ${ work }
                                            ` 
                                            : 
                                            "" 
                                    }
                                </summary>

                                ${ 
                                    ["1", "2", "3"].indexOf(rating) === -1 ? 
                                            services ?  
                                            `
                                                <footer class="map-bubble__footer">
                                                    ${ 
														!services.match(/<p><\/p>/) ?
															`
																<p class="map-bubble__title">GP Services</p>
																${ services }
															`
														:
															""
													}
                                                </footer>
                                            `
                                            :
                                            ""
                                        :
                                        ""
                                }

                            ${ 
                                town ? 
                                    `<a class="map-bubble__town-link" href="${ townLink }" target="_blank">${ town }</a>`
                                :
                                    ""
                            }

                            </main>

                        </div>
                    `;
                }

                // the map list callback
                function mapList (data) {
                    let { title, titleLink, img, rating, work, services, training, town, townLink } = data;

                    const listingWrapper = document.querySelector("[data-map-listings-wrapper]");

                    if (services) {
                        const tmp = document.createElement("div");

                        tmp.innerHTML = services;

                        const anchorTags = tmp.querySelectorAll("a");

                        anchorTags.forEach(anchor => {
                            anchor.setAttribute("target", "_blank");
                        });

                        services = tmp.innerHTML;
                    }

                    listingWrapper.innerHTML += `
                        <div class="map-bubble" data-map-listing data-region="${ region }" data-rating="${ rating }" data-listing-active="true">

                            ${ img ? 
                                    `
                                        <header class="map-bubble__header">
                                            <img src="${ img }" alt="">
                                        </header>
                                    `
                                    :
                                    ""
                            }
                            
                            <main class="map-bubble__wrapper">
                                <summary class="map-bubble__body">
                                    <p class="map-bubble__title">
                                        <a class="map-bubble__title-link btn-text-blue" href="${ titleLink }" target="_blank">
                                            ${ title }

                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <rect y="0.496826" width="14" height="14" rx="7" fill="#3070B3"/>
                                                <path d="M6 4.49683L9 7.49683" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6 10.4968L9 7.49683" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a>
                                    </p>

                                    <div class="map-bubble__details">
                                    <p class="map-bubble__m-rating">MMM ${ rating }</p>
										${ 
											training ? 
												`
													
													${ 
														!training.match(/<p><\/p>/) ? 
															`<p class="map-bubble__opportunities">AST Training Opportunities</p>` 
															: 
															"" 
													}

													${ training }
												` 
												: 
												"" 
										}

										${ 
											work ? 
												`
					
													${ 
														!work.match(/<p><\/p>/) ? 
															`<p class="map-bubble__opportunities">AST Work Opportunities</p>` 
														: 
															"" 
													}

													${ work }
												` 
												: 
												"" 
										}
                                    </div>
                                </summary>

                                ${ 
                                    ["1", "2", "3"].indexOf(rating) === -1 ? 
                                            services ?  
                                            `
                                                <footer class="map-bubble__footer">
                                                    ${ 
														!services.match(/<p><\/p>/) ?
															`
																<p class="map-bubble__title">GP Services</p>
																${ services }
															`
														:
															""
													}
                                                </footer>
                                            `
                                            :
                                            ""
                                        :
                                        ""
                                }

                                ${ 
                                    town ? 
                                        `<a class="map-bubble__town-link" href="${ townLink }" target="_blank">${ town }</a>`
                                    :
                                        ""
                                }
                            </main>

                        </div>
                    `;
                }
                
                // end callbacks
                //////////////////////////////////////////////////
            //}, 1500);
        }
    });
})();
