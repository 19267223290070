export default class ModalComponent {
    constructor ({ toggle }) {
        if (toggle) {
            this.toggleData = toggle;
            this.toggleNodes = document.querySelectorAll(`[${ this.toggleData }]`);

            this.toggled = null;

            this.toggleNodes.forEach(toggle => {
                toggle.addEventListener("click", this._open.bind(this));
            });

            // close the modal when pressing escape
            document.addEventListener("keyup", ({ key }) => {
                if (key === "Escape") this._close();
            });
        }
    }

    /**
     * gets the video id out of a vimeo video link to
     * insert it into a vimeo iframe
     *
     * @param {string} link
     * the link to pull the video id from
     *
     * @return {string}
    */
    _videoEmbed (link) {
        if (!link.match(/\//g)) return link;

        const linkSplit = link.split("/");

        return linkSplit[linkSplit.length - 1];
    }

    /**
     * get a specific ancestor node relative to a child node
     *
     * @param {HTMLElement} child
     * the child element reference for the ancestor element
     *
     * @param {string} parentDataAttr
     * the data attribute for the desired parent element to 
     * return
     *
     * @return {HTMLElement}
    */
    _getParentNode (child, parentDataAttr) {
        while (child) {
            if (child.hasAttribute(parentDataAttr)) {
                return child;
            }

            child = child.parentNode;
        }
    }

    /**
     * opens the modal component
    */
    _open ({ target }) {
        const wrapper = document.createElement("div"),
            button = !target.localName.match("/button/i") ? this._getParentNode(target, this.toggleData) : target,
            dataset = button.dataset,
            videoData = dataset.videoId,
            title = dataset.videoTitle ? dataset.videoTitle : "",
            description = dataset.videoDescription ? dataset.videoDescription : "",
            transcription = dataset.videoTranscription ? dataset.videoTranscription : "",
            video = this._videoEmbed(videoData ? videoData : null);

        wrapper.setAttribute("class", "video-modal");
        wrapper.innerHTML = `
            <!-- modal body -->
            <div class="video-modal__modal-inner">
                <!-- header -->
                <div class="video-modal__header">
                    ${ transcription ? 
                        `<span tabindex="0" data-focus-at="transcription"></span>`
                        :
                        `<span tabindex="0" data-focus-at="video-player"></span>`
                    }
                        

                    <button aria-label="Close video player" data-modal-close class="video-modal__close">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                    </button>
                </div>
                <!-- end header -->

                <!-- video player -->
                <div class="video-modal__video-container">
                    <iframe tabindex="0" data-video-player src="https://player.vimeo.com/video/${ video }?h=d96c18d7e0&autoplay=1&byline=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                <!-- end video player -->

                <!-- video info -->
                <div class="video-modal__info">
                    <h3 class="video-modal__title">${ title }</h3>

                    ${ description }

                    ${ transcription ? 
                            `<a class="btn-blue-text video-modal__transcription" data-transcription href="${ transcription }">Video Transcription</a>`
                            :
                            ""
                    }

                    <span tabindex="0" data-focus-at="modal-close"></span>
                </div>
                <!-- end video info --> 
            </div>
            <!-- end modal body -->
        `;

        this.wrapper = wrapper;

        this.toggled = button;

        document.body.append(wrapper);

        const close = document.querySelector("[data-modal-close]");

        close.focus();

        // modal event listeners
        close.addEventListener("click", this._close.bind(this));
        this.wrapper.addEventListener("keyup", this._modalFocus.bind(this));
    }

    /**
     * closes the modal component
    */
    _close () {
        if (this.wrapper) { 
            this.wrapper.remove();
            this.toggled.focus();
        }
    }

    /**
     * kep focus within the context of the modal
    */
    _modalFocus ({ target, key }) {
        if (key === "Tab" && target.dataset.focusAt) {
            this.wrapper.querySelector(`[data-${target.dataset.focusAt}]`).focus();
        }
    }

    /**
     * gets the parent not of a selected child node
     *
     * @param {HTMLElement} child
     * the child element to get ancestor node from
     *
     * @param {string} parentData
     * the data attribute associated with the parent node
     *
     * @return void
    */
    _getDataset (child, parentData) {
        while (child) {
            if (child.localName && child.hasAttribute(parentData)) {
                return child.dataset;
            }

            child = child.parentNode;
        }

        return null;
    }

    openExample () {
        const wrapper = document.createElement("div");

        wrapper.setAttribute("class", "video-modal");
        wrapper.innerHTML = `
            <!-- modal body -->
            <div class="video-modal__modal-inner">
                <!-- header -->
                <div class="video-modal__header">
                    <span tabindex="0" data-focus-at="transcription"></span>
                        

                    <button aria-label="Close video player" data-modal-close class="video-modal__close">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                    </button>
                </div>
                <!-- end header -->

                <!-- video player -->
                <div class="video-modal__video-container">
                    <iframe src="https://player.vimeo.com/video/734173778?h=213e6a5f83&byline=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                <!-- end video player -->

                <!-- video info -->
                <div class="video-modal__info">
                    <h3 class="video-modal__title">Video Title Area</h3>
                    <p class="video-modal__description">Video description area</p>

                    <a class="btn-blue-text video-modal__transcription" data-transcription href="#">Video Transcription</a>

                    <span tabindex="0" data-focus-at="modal-close"></span>
                </div>
                <!-- end video info --> 
            </div>
            <!-- end modal body -->
        `;

        this.wrapper = wrapper;

        //this.toggled = button;

        document.body.append(wrapper);

        const close = document.querySelector("[data-modal-close]");

        close.focus();

        // modal event listeners
        this.wrapper.addEventListener("keyup", this._modalFocus.bind(this));
    }
}
