(function(){
    'use strict';

    // Global variables
    var squiz = {
        'fn': {},
        'vars': {}
    };

    // Functions
    squiz.fn.sampleFunction = function() {

    };

    // Make squiz variable available to the console for debugging
    window.squiz = squiz;
}());
