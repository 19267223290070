export default class Filters {
    constructor ({ filterSubmit, filterPanelOpenState, closeFilterPanel, filterPanel, openButton }) {
        // static
        this.filterPanel = document.querySelector(filterPanel);
        this.filterSubmit = filterSubmit;
        this.filterPanelOpenState = document.querySelector(filterPanelOpenState);
        this.closeFilterPanel = document.querySelector(closeFilterPanel);
        this.openButton = document.querySelector(openButton);

        // dynamic
        this.overflow = {};
        this.filtered = {};
        this.filterFormatted = {};
        this.checkedFilters = 0;
        this.filterCount = 0;
    }
    // private

    /**
     * this will set the filter count to the button that opens the filter
     * panel to inform the user of how many filtering options they have
     *
     * @return {void}
    */
    //_setFilterCount () {
        //const filterCountSpan = this.openButton.querySelector("span");

        //if (!filterCountSpan) {
            //this.openButton.innerHTML += `<span></span>`;
        //} else {
            //filterCountSpan.innerHTML = this.filterCount;
        //}
    //}

    /**
     * restructures the filtered data gathered by the 
     * _checkFilter event listener
     *
     * this will sort into an array from the most number of checked 
     * items to the least to prepare it for filtering
     *
     * it also stores each set of checked items into grouped arrays, eg:
     *
     * {
     *     cat1 : [ {}, {} ]
     *     cat2 : [ {}, {} ]
     * }
     *
     * becomes:
     *
     * {
     *     cat1 : ["", ""]
     *     cat2 : ["", ""]
     * }
     *
     * @return void
    */
    _formatFilteredData () {
        const groups = [];

        // re-arrange the data structure
        Object.keys(this.filtered).forEach(group => {
            groups.push(this.filtered[group]);
        });

        const desc = groups.sort((a, b) => b.length - a.length),
            merge = {};

        desc.forEach(group => {

            Object.keys(group).forEach(key => {
                const groupKey = group[key];

                Object.keys(groupKey).forEach(cat => {
                    if (!merge[cat]) {
                        merge[cat] = [] 
                    }

                    merge[cat].push(groupKey[cat]);
                });
            });
        });

        this.filterFormatted = merge;
    }

    /**
     * this is an event listener bound to every checkbox that
     * checks the state of the checkbox and will add it to a 
     * corresponding filter group in this.filtered.
     *
     * The key of each group corresponds to the name given in the
     * set() method.
     *
     * eg of this.filtered:
     *
     * {
     *    nameOne : [],
     *    nameTwo : []
     * }
     *
     * @return {void}
    */
    _checkFilter ({ target }) {
        const group = target.dataset.filterGroup,
            filterGroup = this.filtered[group],
            category = target.dataset.filterCat,
            filter = {};

        // add filters to group
        if (target.checked) {
            filter[category] = target.value;
            filterGroup.push(filter);
            this.checkedFilters++;

            this._setCheckedFilters();
            this._formatFilteredData();

            return;
        }

        // remove filters from group
        let groupList = this.filtered[group],
            groupName = group;

        groupList.forEach((group, i) => {
            if (group[category] === target.value) {
                this.filtered[groupName][i] = "";
                this.filtered[groupName] = this.filtered[groupName].filter(Boolean);
            }
        });

        this.checkedFilters--;

        this._setCheckedFilters();
        this._formatFilteredData();
    }

    /**
     * this method applies the number of selected filters to the 
     * "apply filters" button in the filter panel
     *
     * @return {void}
    */
    _setCheckedFilters () {
        const filterCount = this.filterSubmit.querySelector("span"),
            filterCountSpan = this.openButton.querySelector("span");

        if (this.checkedFilters) {
            if (!filterCount) {
                const span = document.createElement("span");

                span.innerHTML = `(${ this.checkedFilters })`;

                this.filterSubmit.append(span);

                //this.filterSubmit.innerHTML += ` <span>(${ this.checkedFilters })</span>`;
            } else {
                filterCount.innerHTML = `(${ this.checkedFilters })`;
            }

            if (!filterCountSpan) {
                const span = document.createElement("span");

                span.innerHTML = `(${ this.checkedFilters })`;

                this.openButton.append(span);

                //this.openButton.innerHTML += `<span>(${ this.checkedFilters })</span>`;
            } else {
                filterCountSpan.innerHTML = `(${ this.checkedFilters })`;
            }
        } else {
            if (filterCount) {
                filterCount.remove();
            }

            if (filterCountSpan) {
                filterCountSpan.remove();
            }
        }
    }

    /**
     * this is an event listener for the "See all {name}" buttons in the
     * filter panel
     *
     * this method updates values set in the set() method such as the 
     * label applied to the "see all {name}" button
     *
     * this will 
     *
     * @return {void}
    */
    _viewMore ({ target }) {
        const name = target.dataset.overflow,
            load = this.overflow[name],
            count = load.count,
            parent = target.parentNode.querySelector("[data-extra-filters]"),
            isExpanded = parent.dataset.toggled === "true" ? true : false;

        // overflow <ul> is closed - open it
        if (!isExpanded) {
            parent.dataset.toggled = "true";

            target.textContent = `See less ${ name }`;
            target.setAttribute("aria-expanded", "true");

            return;
        }

        // overflow <ul> is opened - close it
        parent.dataset.toggled = "false";

        target.setAttribute("aria-expanded", "false");
        target.textContent = `See all ${ name } (${ count + 1 })`;
    }

    /**
     * this is a keyup event listener to keep focus within 
     * the filter panel once it has opened
     *
     * @return {void}
    */
    _filterPanelFocusContext ({ target }) {
        if (target.dataset.focusAt) {
            this.filterPanel.querySelector(`[data-${ target.dataset.focusAt }]`).focus();
        }
    }

    /**
     * event listener that sets all filters of a particular category to 
     * a checked state
     *
     * @return {void}
    */
    _checkAllEvent ({ target }) {
        const filter = target.dataset.selectAll,
            filterList = document.querySelectorAll(`[data-filter-cat="${ filter }"]`);

        filterList.forEach(filter => {
            if (target.checked && !filter.checked) {
                filter.click();
            }
            else if (!target.checked && filter.checked) {
                filter.click();
            }
        });
    }

    // public
    /**
     * sets the open state for the filter panel
     *
     * @param {boolean} state
     * whether or not the filter panel is open
     *
     * @return {void}
    */
    filterPanelState (state) {
        this.filterPanelOpenState.dataset.filtersOpen = state;

        if (state) {
            this.closeFilterPanel.focus();

            this.openButton.setAttribute("aria-expanded", "true");

            this.filterPanel.addEventListener("keyup", this._filterPanelFocusContext.bind(this));

            return;
        }

        this.openButton.setAttribute("aria-expanded", "false");
    }

    /**
     * gets all set filters
     *
     * @param {callable} callback
     * this callback takes a single parameter which
     * is a list of filtered data
     *
     * @return {void}
    */
    submit (callback) {
        this.filterSubmit.addEventListener("click", () => {
            if (Object.keys(this.filterFormatted).length) {
                callback(this.filterFormatted);
            }
        });
    }

    /**
     * clears all filters away
     *
     * @return {void}
    */
    clear () {
        const filterInputs = document.querySelectorAll(`[data-filter-cat]`),
            checkAll = document.querySelectorAll("[data-select-all]"),
            submitFilterCount = this.filterSubmit.querySelector("span"),
            filterPanelOpenCount = this.openButton.querySelector("span");

        if (submitFilterCount) submitFilterCount.remove();

        if (filterPanelOpenCount) filterPanelOpenCount.remove();

        checkAll.forEach(check => check.checked = false);

        this.checkedFilters = 0;
        this.filterFormatted = {};

        Object.keys(this.filtered).forEach(key => {
            this.filtered[key] = [];
        });

        filterInputs.forEach(input => {
            input.checked = false;
        });
    }

    /**
     * sets filter options
     *
     * @param {string} name
     * the name of this filter (this will be reflected in the "see more {name}" button)
     *
     * @param {HTMLElement} element
     * this is expecting a <ul> tag as it will append <li> elements to it
     *
     * @param {int} maxDisplay
     * the maximum filter checkboxes to display, if the number of filter 
     * controls exceed this set number a "see more" button will display under the
     * filter checkboxes
     *
     * @param {array} options
     * the options are very important and require 3 properties:
     *
     * name - the label that will appear next to the checkbox
     *
     * value - the value of the checkbox input
     *
     * category - the data category this value belongs to
     *
     * @return {void}
    */
    set (name, element, maxDisplay, options) {
        let overflowCount = 0,
            settingName = name,
            hasSelectAll = element.querySelector("[data-select-all]"),
            overflowItems = [];

        this.filtered[name] = [];

        // append <li> elements to the element param
        options.forEach((option, i) => {
            this.filterCount++;

            const { name, value, category } = option,
                id = name.replace(/ /g, "-").toLowerCase(),
                filterItem = document.createElement("li");
            
            if (hasSelectAll) {
                filterItem.classList.add("filter-panel__list-fully-selectable");
            }

            //filterItem.innerHTML = `
                //<label class="filter-panel__input-wrapper" for="${ id }">
                    //<input data-filter-group="${ settingName }" data-filter-cat="${ category }" id="${ id }" value="${ value }" type="checkbox">

                    //<div class="filter-panel__faux-checkbox"></div>

                    //${ name }
                //</label>
            //`;

            filterItem.innerHTML = `
                <label class="filter-panel__input-wrapper" for="${ id }" tabindex="0" data-filter-option>
                    <input data-filter-group="${ settingName }" data-filter-cat="${ category }" id="${ id }" value="${ value }" type="checkbox" hidden>

                    <div class="filter-panel__faux-checkbox"></div>

                    ${ name }
                </label>
            `;


            overflowCount++;

            if (maxDisplay) {
                if (i < maxDisplay) {
                    element.append(filterItem);
                } else {
                    overflowItems.push(filterItem);
                }
            } else {
                element.append(filterItem);
            }
        });

        // set an overflow button and <ui> if the set number of inputs are exceeded
        if (maxDisplay && overflowItems.length) {
            const button = document.createElement("button"),
                ul = document.createElement("ul");

            button.setAttribute("data-overflow", name);
            button.setAttribute("data-toggled", "false");
            button.setAttribute("class", "filter-panel__show-more");
            button.setAttribute("aria-controls", `${ name }-expander`);
            button.setAttribute("aria-expanded", "false");

            button.textContent = `See all ${name} (${ overflowCount })`;

            this.overflow[name] = {};
            this.overflow[name].markup = overflowItems;
            this.overflow[name].count = overflowCount - 1;
            this.overflow[name].max = maxDisplay;

            ul.setAttribute("class", "atcb-list filter-panel__extra-filters");
            ul.setAttribute("data-extra-filters", "");
            ul.setAttribute("data-toggled", "false");
            ul.setAttribute("id", `${ name }-expander`);

            overflowItems.forEach(item => {
                ul.append(item);
            });

            element.parentNode.append(ul);
            element.parentNode.append(button);

            button.addEventListener("click", this._viewMore.bind(this));
        }

        const filterChecks = document.querySelectorAll(`[data-filter-group="${ name }"]`);

        filterChecks.forEach(filter => {
            filter.addEventListener("change", this._checkFilter.bind(this));
        });

        //this._setFilterCount();
    }

    /**
     * enables the filter checkbox input list to be completely
     * checked
     *
     * @param {HTMLElement} element
     * the element to append the check all input box to
     *
     * @param {string} name
     * the label to provide the checkall input
     *
     * @param {string} target
     * the category this checkbox is to target
     *
     * @return {void}
    */
    checkall (element, name, target) {
        const id = target.replace(/ /g, "-").toLowerCase(),
            checkAllLi = document.createElement("li");

        //checkAllLi.innerHTML = `
            //<label class="filter-panel__input-wrapper" for="${ id }">
                //<input data-select-all="${ target }" id="${ id }" type="checkbox">

                //<div class="filter-panel__faux-checkbox"></div>

                //${ name }
            //</label>
        //`;

        checkAllLi.innerHTML = `
            <label class="filter-panel__input-wrapper" tabindex="0" data-filter-option>
                <input data-select-all="${ target }" id="${ id }" type="checkbox" hidden>

                <div class="filter-panel__faux-checkbox"></div>

                ${ name }
            </label>
        `;

        //element.innerHTML += markup;
        element.append(checkAllLi);

        setTimeout(() => {
            const selectAll = document.querySelector(`[data-select-all="${ target }"]`);

            selectAll.addEventListener("click", this._checkAllEvent.bind(this));
        }, 500);
    }
}
