import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

/**
 * @namespace Map
*/
export default class Map {
    constructor ({ api, map, initLat, initLng, settings }) {
        // static
        this.api = api; // path to the API source
        this.map = map; // the map <div>
        this.initLat = initLat ? initLat : -32.63997616103031; // set or default lat
        this.initLng = initLng ? initLng : 146.18771237145015; // set or default lng
        this.settings = settings ? settings : {}; // settings to provide the Google Map object

        this._api();

        // marker icon
        this.marker = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="41" viewBox="0 0 30 41" fill="none">
            <path d="M28.75 15C28.75 7.4 22.6 1.25 15 1.25C7.4 1.25 1.25 7.4 1.25 15C1.25 17.9 2.15 20.5875 3.6875 22.8125L15 38.75L26.3125 22.8125C27.85 20.6 28.75 17.9125 28.75 15Z" fill="#C05131"/>
            <path d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z" fill="white"/>
        </svg>`;

        this.cluster = `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" x="0" y="0" version="1.1" viewBox="0 0 27.33 27.33"><circle fill="#c05131" cx="13.66" cy="13.66" r="11"/><path d="M13.66 27.33C6.13 27.33 0 21.2 0 13.66S6.13 0 13.66 0s13.66 6.13 13.66 13.66-6.12 13.67-13.66 13.67zm0-26.33C6.68 1 1 6.68 1 13.66s5.68 12.66 12.66 12.66 12.66-5.68 12.66-12.66S20.65 1 13.66 1z" fill="#fff" opacity=".08"/></svg>`;

        // dynamic
        this.markers = []; // list of marker locations
        this.merge = []; // merged filter data

        setTimeout(() => {
            // init
            this.mapWindow = this._initMap(); // initialize the map
        }, 500);
    }

    // private methods

    /**
     * instantiates the google map and returns
     * the google map instance
     *
     * @return {object} 
    */
    _initMap () {
        const settings = this.settings,
            map = new window.google.maps.Map(this.map, {
            zoom : window.innerWidth < 768 ? 5.6 : 6.5,
            center: new window.google.maps.LatLng(this.initLat, this.initLng),
            ...settings
        }),
            radius = window.innerWidth < 768 ? 120 : 200;

        const renderer = {
                render: ({ count, position }) =>
                  new window.google.maps.Marker({
                        icon : {
                            url : `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(this.cluster)}`,
                            scaledSize : new window.google.maps.Size(50, 50)
                        },
                        label: { text: String(count), color: "white", fontSize: "12px" },
                        position,
                        // adjust zIndex to be above other markers
                        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count
                }),
            };

        if (window.innerWidth > 768) {
            const algorithm = new SuperClusterAlgorithm({ 
                radius : radius,
                maxZoom : 7
            }),
            markerClusterer = new MarkerClusterer({ 
                map : map,
                renderer,
                algorithm
            });

            this.markerClusterer = markerClusterer;
        }

        this._markers(map);

        return map;
    }
    /**
     * this is an event listener that applies markers
     * the the map along with a custom selected marker icon
     *
     * @param {array} locations
     * a list of data that will contain a lat and lng for the
     * location marker destination
     *
     * @param {object} map
     * the Google Maps object
     *
     * @return {void}
    */
    _doMarkers (locations, map) {
        //const markerList = [];

        locations.forEach(info => {
            //console.log(map);
            const marker = new window.google.maps.Marker({
                position : new window.google.maps.LatLng(info.lat, info.lng),
                icon : {
                    url : `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(this.marker)}`,
                    scaledSize : new window.google.maps.Size(32, 32)
                },
                map: map
            });

            //markerList.push(marker);

            this.markers.push(marker);
        });

        this.markerClusterer.clearMarkers();

        this.markerClusterer.addMarkers(this.markers);
    }
    /**
     * adds custom markers to the map
     *
     * @param {object} map
     * the google maps instance
     *
     * @return {void}
    */
    _markers (map, filter, filterBy) {
        const data = this.apiData;

        //this._api((data) => {
            if (!filter) {
                this._doMarkers(data.locations, map);
            } else {
                const filterData = this._filter(filter, data, filterBy);

                this.markers.forEach(marker => {
                    marker.setMap(null);
                });

                this.markers = [];

                this._doMarkers(filterData, map);
            }
        //});
    }

    /**
     * fetches an api to populate the map with location data
     *
     * @param {callable} calback
     * the callback function takes a single parameter which is the 
     * successfully fetched map data
     *
     * @return {void}
    */
    _api (callback) {
        fetch(this.api)
            .then(res => res.json())
            .then(data => {
                //callback(data);
                this.apiData = data;
            });
    }

    /**
     * takes filter data from the filter class and re-structures
     * it then performs the filter
     *
     * @param {object} the filter class' filtered values. This is based
     * on the filter class' set() method, expected output should be:
     * 
     * [{category : value}]
     *
     * @param {object} data
     * the data that is brought in via the Map class' _api() method
     *
     * @param {array} filterBy
     * the data to filter, this corresponds to the expected filter
     * category names, eg:
     *
     * ["category"]
    */
    _filter (filter, data, filterBy) {
        // do the filtering
        const filtered = data.locations.map(data => {
            const validate = [];

            filterBy.forEach(cat => {
                if (filter[cat]) {
                    validate.push(filter[cat].indexOf(data[cat]) !== -1);
                }
            });

            const index = validate.indexOf(false);

            if (!(index >= 0)) {
                return data;
            } else {
                return;
            }
        });

        return filtered.filter(Boolean);
    }

    /**
     * loops through user designed & defined bubble
     * markup along with bubble data
     *
     * @param {callable} callback
     * the bubble() method's callback function
     *
     * @param {array} locations
     * the locations to loop through
     *
     * @param {array} markers
     * map markers to apply bubbles to
     *
     * @param {object} map
     * the Google Map object
     *
     * @param {array} clicked
     * this method provides a reference to whether a 
     * bubble has been clicked or not so as not to append
     * multiple of the same bubble
     *
     * @return {void}
    */
    _doBubble (callback, locations, markers, map, filtered) {
        let currentBubble = false,
            clicked = "";

        locations.forEach((info, i) => {
            const markup = callback(info),
                infoWindow = new window.google.maps.InfoWindow({
                    content : markup,
                });

            if (markers[i]) {
                markers[i].addListener("click", () => {
                    if (currentBubble) {
                        currentBubble.close();
                        currentBubble = false;

                        if (clicked) {
                            clicked = "";
                        }
                    } 

                    currentBubble = infoWindow;

                    // prevent the bubble from opening multiple times
                    if (clicked !== info.title) {
                        infoWindow.open({
                            anchor : markers[i],
                            map,
                            shouldFocus : false
                        });

                        clicked = info.title;
                    }
                });
            }

            this.markerClusterer.addListener("click", () => {
                if (currentBubble) {
                    currentBubble.close();
                    currentBubble = false;

                    if (clicked) {
                        clicked = "";
                    }
                } 
            });

            infoWindow.addListener('closeclick', () => {
                clicked = "";
            });
        });
    }

    // public 
    /**
     * this will enable bubbles to be displayed on the map when 
     * clicking on markers
     *
     * @param {callable} callback
     * the callback takes one param which will be the result of the internal
     * _api method which will contain relevant map data
     *
     * the callback must return a string value that will provide HTML structure to the
     * map marker bubble, eg:
     *
     * map.bubble(({ title, titleLink, image, rating, description, gpServices }) => {
     *     return `
     *         <h1>${ title }</h1>
     *     `;
     * });
    */
    bubble (callback, filter, filterBy) {
        //this._api(data => {
        setTimeout(() => {
            const data = this.apiData;

            const markers = this.markers,
                map = this.mapWindow;

            if (!filter) {
                this._doBubble(callback, data.locations, markers, map);
            } else {
                const locations = this._filter(filter, data, filterBy);

                this._doBubble(callback, locations, markers, map, true);
            }
        }, 500);
        //});
    }

    /**
     * this method will return the JSON data for the map via
     * the internal _api method.
     *
     * @param {callable} callback
     * the callback method will take one param which will be the JSON data
     *
     * @return {void}
    */
    getList (callback, filter, filterBy) {
        //this._api((data) => {
        setTimeout(() => {
            const data = this.apiData;

                if (!filter) {
                    data.locations.forEach(info => {
                        callback(info);
                    });
                } else {
                    const filterData = this._filter(filter, data, filterBy);

                    filterData.forEach(info => {
                        callback(info);
                    });
                }
            //});

            this.merge = filterBy;
        }, 500);

        return this;
    }

    /**
     * sends filtered data to the _markers() method
     *
     * @return {void}
    */
    refreshMarkers (filter, filterBy) {
        this._markers(this.mapWindow, filter, filterBy);
    }

    centerMap () {
        this.mapWindow.setZoom(window.innerWidth < 768 ? 5.6 : 6.5);
        this.mapWindow.setCenter(new window.google.maps.LatLng(this.initLat, this.initLng));
    }

    moveMapToLocation (location, data) {
        if (location.length > 1) {
            this.mapWindow.setZoom(window.innerWidth < 768 ? 5.6 : 6.5);
            this.mapWindow.setCenter(new window.google.maps.LatLng(this.initLat, this.initLng));

            return;
        }

        const localReg = new RegExp(location[0], "i"), 
            locationData = data.filter(local => local.name.match(localReg));

        if (locationData.length) {
            this.mapWindow.panTo(new window.google.maps.LatLng(locationData[0].lat, locationData[0].lng));
            this.mapWindow.setCenter(new window.google.maps.LatLng(locationData[0].lat, locationData[0].lng));
            this.mapWindow.setZoom(window.innerWidth < 768 ? 6.5 : 6.8);

            return;
        }

        this.mapWindow.setZoom(window.innerWidth < 768 ? 5.6 : 6.5);
        this.mapWindow.setCenter(new window.google.maps.LatLng(this.initLat, this.initLng));
    }
}
